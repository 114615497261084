import { useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Title from "../GlobalComponents/Title";
import Container from "../GlobalComponents/Container";

import FadeIn from "react-fade-in";
import Spinner from "react-bootstrap/Spinner";
import X from "./x";
import ReactPlayer from "react-player";
import axios from "axios";
import BASE_URL from "../../constant";

const Services = () => {
  const [alldata, setAllData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api_refresh();
  });

  const api_refresh = () => {
    axios.get(`${BASE_URL}/all_videos`).then((response) => {
      console.log(response.data.all_videos);
      setAllData(response.data.all_videos);
      setLoading(false);
    });
  };

  return (
    <section css={styles} className="services">
      <X />
      <Title title="All videos available" />
      <Container>
        {loading ? (
          <div style={{ padding: 150 }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 10,
                flexWrap: "wrap",
              }}
            >
              {alldata.map((item) => (
                <div>
                  <FadeIn delay={300} transitionDuration={3000}>
                    <ReactPlayer
                      style={{ marginBottom: 25, marginRight: 35 }}
                      className="youtube"
                      url={item.name}
                      width={340}
                      height={190}
                      controls={true}
                    />{" "}
                  </FadeIn>
                </div>
              ))}
            </div>
          </div>
        )}
      </Container>
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 110px 0;
  background: #fff;

  @media (min-width: 1920px) {
    width: 100%;
    padding: 140px 0;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 110px 0;
  }

  .button-view {
    background: #000;
    color: #fff;
    background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
    &:hover {
      color: #fff;
      background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
    }
  }
  .carousel {
    display: flex;
    align-items: flex-end;
    width: 300px;
    height: 200px;
    border-radius: 50px;
  }
  .title {
    margin-top: 120px;
    color: #0d3c61;
    font-size: 35px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 0 0;
    overflow: hidden;
  }
  button {
    background: #fff;
    border: none;
    color: #000;
    padding: 20px;
    border-radius: 10px;
    width: 350px;
    height: 220px;
    margin: 10px;
    box-shadow: 0px 12px 32px #383b470f;
    color: #0d3c61;
    font-size: 16px;
    white-space: wrap;
    font-weight: 600;
    /* border: 1px solid black; */
    transition: ease-in-out;
    /* border-left: 3px solid #1488cc; */
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    &:hover {
      /* background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%); */
      /* transform: scale(1.07); */
      transition: ease-in-out;
      color: #fff;
      /* border-left: 3px solid #000; */

      span {
        color: #000;
      }
    }
    img {
      height: 70px;
      width: 70px;
      /* opacity: 0.6; */
    }
    span {
      font-size: 15px;
      /* font-weight: 900; */
    }
  }

  @media (max-width: 868px) {
    .title {
      margin-top: -8%;
    }
    .container {
      flex-direction: column;
      align-items: center;
      margin-top: -10px;
    }
  }
  @media (min-width: 869px) and (max-width: 1175px) {
    .title {
      margin-top: -8%;
    }
    .container {
      max-width: 766px;
      margin-top: -10px;
    }
  }
`;

export default Services;
