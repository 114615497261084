import React, { useState, useEffect } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Title from "../GlobalComponents/Title";
import Container from "../GlobalComponents/Container";
import ReactBnbGallery from "react-bnb-gallery";
import FadeIn from "react-fade-in";
import Spinner from "react-bootstrap/Spinner";
import X from "./x";
import axios from "axios";
import BASE_URL from "../../constant";

const Services = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [view, setView] = useState([]);

  const [index, setIndex] = useState(0);
  const [alldata, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api_refresh();
    // setData(Data);
  });
  const api_refresh = () => {
    axios.get(`${BASE_URL}/all_photo_albums`).then((response) => {
      console.log(response.data.all_photos);
      setAllData(response.data.all_photos);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    });
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <section css={styles} className="services">
      <X />
      <Title title="All photo albums" />
      <Container>
        {alldata.map((item) => (
          <div>
            <FadeIn delay={300} transitionDuration={3000}>
              <button
                onClick={() => {
                  setIsOpen(true);
                  setView(item.imgCollection);
                }}
              >
                <span> {item.name}</span>

                <br />
                <br />
                {!loading ? (
                  item.imgCollection.slice(0, 3).map((subitem) => {
                    return (
                      <img
                        src={subitem}
                        style={{
                          height: 50,
                          width: 50,
                          borderRadius: 5,
                          margin: 3,
                        }}
                      />
                    );
                  })
                ) : (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}

                <br />
                <br />
                <span
                  className="button-view"
                  style={{
                    padding: 4,
                    margin: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                    borderRadius: 50,
                  }}
                >
                  Click To view
                </span>
              </button>
              {/* <div
              className="carousel"
              onClick={() => {
                setIsOpen(true);
                setView(item.pictures);
              }}
            >
              <Carousel activeIndex={index} onSelect={handleSelect}>
                {item.pictures.map((subitem) => {
                  return (
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={subitem.photo}
                        alt="First slide"
                        style={{
                          height: 200,
                          width: 300,
                          borderRadius: 10,
                          objectFit: "cover",
                        }}
                      />
                      <div style={{ padding: 10, backgroundColor: "red" }}>
                        <span> {item.name}</span>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div> */}
            </FadeIn>
          </div>
        ))}
      </Container>
      <ReactBnbGallery
        show={isOpen}
        photos={view}
        onClose={() => setIsOpen(false)}
      />
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 110px 0;
  background: #fff;

  @media (min-width: 1920px) {
    width: 100%;
    padding: 140px 0;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 110px 0;
  }

  .button-view {
    background: #000;
    color: #fff;
    background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
    &:hover {
      color: #fff;
      background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
    }
  }
  .carousel {
    display: flex;
    align-items: flex-end;
    width: 300px;
    height: 200px;
    border-radius: 50px;
  }
  .title {
    margin-top: 120px;
    color: #0d3c61;
    font-size: 35px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 0 0;
    overflow: hidden;
  }
  button {
    background: #fff;
    border: none;
    color: #000;
    padding: 20px;
    border-radius: 10px;
    width: 350px;
    height: 220px;
    margin: 10px;
    box-shadow: 0px 12px 32px #383b470f;
    color: #0d3c61;
    font-size: 16px;
    white-space: wrap;
    font-weight: 600;
    /* border: 1px solid black; */
    transition: ease-in-out;
    /* border-left: 3px solid #1488cc; */
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    &:hover {
      /* background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%); */
      /* transform: scale(1.07); */
      transition: ease-in-out;
      color: #fff;
      /* border-left: 3px solid #000; */

      span {
        color: #000;
      }
    }
    img {
      height: 70px;
      width: 70px;
      /* opacity: 0.6; */
    }
    span {
      font-size: 15px;
      /* font-weight: 900; */
    }
  }

  @media (max-width: 868px) {
    .title {
      margin-top: -8%;
    }
    .container {
      flex-direction: column;
      align-items: center;
      margin-top: -10px;
    }
  }
  @media (min-width: 869px) and (max-width: 1175px) {
    .title {
      margin-top: -8%;
    }
    .container {
      max-width: 766px;
      margin-top: -10px;
    }
  }
`;

export default Services;
