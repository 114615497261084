/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const ServiceCard = ({ serviceIcon, serviceTitle, img, link, desc }) => {
  return (
    <div css={styles} className="serviceCard">
      <a href={link}>
        <div className="serviceInfo">
          <b>Sponsor Name :</b>
          <h3> {serviceTitle}</h3>
          <b>About Sponsor :</b>
          <p>{desc}</p>
        </div>
      </a>
    </div>
  );
};

const styles = css`
  width: 100%;
  max-width: 280px;
  margin: 20px 0;
  background: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  border-left: 3px solid #bcdffb;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 12px 0px;

  a {
    text-decoration: none;
  }

  &:hover {
    .overlay {
      opacity: 1;
      top: 0;
    }
    .serviceInfo {
      position: relative;

      h3 {
        color: #000;
        transition: all 600ms ease-in-out;
      }
      p {
        color: #72789a;
        transition: all 600ms ease-in-out;
        font-weight: 100px;
      }
    }
    .iconContainer {
      background: #000;
      position: relative;
      transition: all 600ms ease-in-out;
    }
  }

  .iconContainer {
    background: #c3cfe2;
    width: 0px;
    height: 0px;
    border-radius: 50px;
    display: flex;
    img {
      width: 50px;
      height: 20px;

      /* border-radius: 50px; */
    }
    i {
      margin: auto;
      color: #fff;
      font-size: 17px;
    }
  }
  .serviceInfo {
    padding: 0px 0;
    width: 100%;
    max-width: 180px;
    margin-left: 60px;
    h3 {
      line-height: 1.5;
      font-weight: 800;
      color: #203d67;
      font-size: 17px;
    }
    p {
      margin: 16px 0;
      color: #72789a;
      font-weight: 700;
      line-height: 1.7;
    }
  }
  @media (max-width: 868px) {
    max-width: 450px;
    .serviceInfo {
      max-width: 100%;
    }
  }
`;

export default ServiceCard;
