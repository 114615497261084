import { useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";


const ContactCardContainer = () => {
  const [d, setd] = useState(600175);

  useEffect(() => {
    var rand_num = Math.floor(Math.random() * 6 + 1);
    var result = d + rand_num;
    setd(result);
  }, []);

  return (
    <div css={styles} className="contactCardContainer">
      <div className="contactCard firstCard">
        <h2>Copyright © </h2>
        Concern Sri Lanka Foundation [CSLF]
        <br /> <br />
        {d}-Page views
        <div className="socialContainer">
          <div className="circle">
            <i className="fab fa-twitter"></i>
          </div>
          <div className="circle">
            <i className="fab fa-facebook-f"></i>
          </div>
          <div className="circle">
            <i className="fab fa-instagram"></i>
          </div>
        </div>
      </div>
      <div className="contactCard secondCard">
        <h2>Useful Links</h2>
        <a href="events">Latest Events</a>
        <a href="/aim">Aim / objective</a>
        <a href="partners">Partners</a>
      </div>
      <div className="contactCard thirdCard">
        <h2>Easy Navigate</h2>
        <a href="/donation">Donation</a>
        <a href="/project">projects</a>
        <a href="/sponsors">Sponsors</a>
      </div>
      <div className="contactCard forthCard">
        <h2>Registered as</h2>
        <div className="row">
          <i className="fas fa-location-arrow"></i> A Charity in England and
          Wales
        </div>
        <div className="row">
          <a href="#/">
            <i className="fas fa-child"></i> Charity No 1153369
          </a>
        </div>
        <div className="row">
          <a href="#/">
            <i className="fas fa-envelope-open"></i> admin@concernsrilanka.org
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  .contactCard {
    width: 100%;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    a {
      color: #fff;
      text-decoration: none;
      font-weight: 600;
      transition: color 300ms ease-in-out;
      &:hover {
        color: #fff;
      }
    }
    &:nth-child(2),
    &:nth-child(3) {
      max-width: 180px;
    }
    h2 {
      color: #fff;
      font-size: 26px;
      line-height: 1;
      margin-bottom: 30px;
      font-weight: 900;
    }
    &.firstCard {
      p {
        font-weight: 600;
        font-size: 15px;
        line-height: 1.9;
      }
      .socialContainer {
        display: flex;
        padding: 20px 0 0 0;
        .circle {
          margin-right: 16px;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          i {
            color: #000;
            margin: auto;
          }
        }
      }
    }
    &.secondCard,
    &.thirdCard {
      a {
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }
    &.forthCard {
      .row {
        display: flex;
        align-items: flex-start;
        i {
          margin-right: 10px;
        }
        &:first-of-type {
          i {
            padding: 3px 0 0 0;
            margin-right: 17px;
          }
        }
        a {
          color: #fff;
        }
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }
  }
  @media (max-width: 690px) {
    flex-direction: column;
    align-items: flex-start;
    .contactCard {
      max-width: 100%;
      margin: 20px 0;
      color: #fff;
    }
  }
  @media (min-width: 691px) and (max-width: 1070px) {
    flex-wrap: wrap;
    justify-content: space-between;
    .contactCard {
      max-width: 47%;
      margin: 20px 0;

      &:nth-child(2),
      &:nth-child(3) {
        max-width: 47%;
      }
    }
  }
`;

export default ContactCardContainer;
