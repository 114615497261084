/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Container from "../GlobalComponents/Container";
import ContactCardContainer from "./FooterContainer";

const Contact = () => {
  return (
    <section css={styles} className="contact" id="contact">
      <Container>
        <ContactCardContainer />
      </Container>
    </section>
  );
};

const styles = css`
  width: 100%;
  background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
  padding: 20px 0 30px;

  @media (max-width: 1010px) {
    background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
    color: #fff;
  }
`;

export default Contact;
