/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const Button = ({ btnText }) => (
  <button className="btn" css={styles}>
    {btnText}
  </button>
);

const styles = css`
  border: none;
  outline: none;
  padding: 10px 34px;

  font-weight: 800;
  color: #fff;
  background: #bcdffb;
  background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
  border-radius: 60px;
  cursor: pointer;
  transition: all 500ms ease-in-out;

  &:hover {
    color: #000;
  }
`;

export default Button;
