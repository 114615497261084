/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const Title = ({ title }) => (
  <h2 className="title" css={styles}>
    {title}
  </h2>
);

const styles = css`
  color: #000;
  font-size: 40px;
  font-weight: 900;
  line-height: 1;
  text-align: center;

  background: -webkit-linear-gradient(90deg, #485563 0%, #29323c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 1010px) {
    color: #000;
    font-size: 20px;
    margin: 20px;
    margin: 0px;
    background: -webkit-linear-gradient(90deg, #485563 0%, #29323c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 868px) {
    margin-top: -10px;
  }
`;

export default Title;
