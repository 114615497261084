
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import {  NavLink } from "react-router-dom";


export default function x() {
  return (
    <div css={styles}>
      <NavLink activeClassName="active" className={"tabx"} to="/sponsors">
        Event Sponsors
      </NavLink>
      <div style={{ marginLeft: 40 }} />
      <NavLink activeClassName="active" className={"tabx"} to="/eventsponsors">
        Project Sponsors
      </NavLink>
    </div>
  );
}

const styles = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 840px) {
    padding: 10px;
    margin-bottom: 100px;
  }
  .container {
    padding: 15px 0px;
    overflow: hidden;
    margin: 0px auto;
  }

  .tabx {
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    font-weight: bolder;
    text-decoration: none;
    letter-spacing: 0.06em;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease-in-out;
    overflow-y: hidden;
    color: #000;
    padding: 10px;
    margin-top: 7%;
    border-radius: 2px;
    margin-left: 20px;
    margin-bottom: -50px;

    &:hover {
      color: #fff;
      background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
    }
  }
  .tabx.active {
    color: #fff;
    background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
  }

  .buttonx {
    background: #fff;

    border: none;
    color: #fff;

    border-radius: 10px;
    width: 35vw;
    height: 10px;
    margin: 0px 4px;
    box-shadow: 0px 12px 3px #383b470f;
    color: #0d3c61;
    font-size: 26px;
    white-space: wrap;
    font-weight: 600;
    padding: 50px;
    /* border: 1px solid black; */
    transition: 0.5s ease-in-out;
    /* border-left: 3px solid #1488cc; */
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    margin-bottom: 30px;
    &:hover {
      /* background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%); */
      /* transform: scale(1.03); */
      transition: 0.5s ease-in-out;
      color: red;
      /* border-left: 3px solid #000; */

      span {
        color: #000;
      }
    }
  }
`;
