import { useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Title from "../GlobalComponents/Title";
import Container from "../GlobalComponents/Container";
import BlogCard from "./BlogCard";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../constant/index";
const Blog = (props) => {
  const [alldata, setAllData] = useState([]);
  const [videodata, setVideoData] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}/all_projects`).then((response) => {
      console.log(response.data.all_projects);
      setAllData(response.data.all_projects);
    });

    axios.get(`${BASE_URL}/all_videos`).then((response) => {
      console.log(response.data.all_videos);
      setVideoData(response.data.all_videos);
    });
  });
  return (
    <section css={styles} className="blog" id="blog">
      <br />
      <div className="blogInfo">
        <Title title="Our Recent Posts !" />
      </div>
      <br />

      <Container>
        {alldata.slice(0, 4).map((item) => (
          <Link style={{ textDecoration: "none" }} to={`/blog/${item._id}`}>
            <BlogCard
              blogImg={item.profileImg}
              date={item.date}
              title={item.name}
              description={item.description}
            />
          </Link>
        ))}
      </Container>
      <Container>
        {alldata.slice(4, 8).map((item) => (
          <Link style={{ textDecoration: "none" }} to={`/blog/${item._id}`}>
            <BlogCard
              blogImg={item.profileImg}
              date={item.date}
              title={item.name}
              description={item.description}
            />
          </Link>
        ))}
      </Container>
      <br />

      <div className="blogInfo" style={{ margin: 30 }}>
        {/* <Button btnText="View other Posts >" className="btn" /> */}
      </div>

      <div className="blogInfo">
        <Title title="Recent Videos" />
      </div>
      <Container>
        {videodata.slice(0, 3).map((item) => (
          <ReactPlayer
            className="youtube"
            url={item.name}
            width={350}
            height={190}
            controls={true}
          />
        ))}
      </Container>
      <Container>
        {videodata.slice(3, 6).map((item) => (
          <ReactPlayer
            className="youtube"
            url={item.name}
            width={350}
            height={190}
            controls={true}
          />
        ))}
      </Container>
      <div className="blogInfo" style={{ margin: 30 }}>
        {/* <Button btnText="View Other Videos >" className="btn" /> */}
      </div>
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 0px;
  background: #fff;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  .container {
    overflow: hidden;
  }

  .blogCard {
    width: 270px;
    height:270px;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    margin: 5px;
  }
  .blogInfo {
    text-align: center;
    p {
      color: #000;
      font-weight: 900px;
      line-height: 1.7;
      margin: 20px 0 0 0;
    }
  }

  .container {
    padding: 10px 0 0 0;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 875px) {
    .container {
      padding: 0px;
    }

    .blogCard {
      max-width: 340px;
      img {
        height: 200px;
      }
    }
    .blogInfo {
      max-width: 90%;
      p {
        font-size: 15px;
        padding: 0 1px;
        br {
          display: none;
        }
      }
    }
  }

  @media (max-width: 641px) {
    .container {
      padding: 0px;
    }
    .blogInfo {
      p {
        font-size: 15px;
        padding: 0 14px;
        br {
          display: none;
        }
      }
    }
  }
  @media (max-width: 840px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
    .youtube {
      margin-bottom: 10px;
    }
  }
  @media (min-width: 841px) and (max-width: 1175px) {
    .container {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

export default Blog;
