import React, { useState, useEffect } from "react";

import "./App.css";
import "react-bnb-gallery/dist/style.css";

// +++++++++++++++++++++++++++++++++++++++++++++
import Nav from "./components/Hero/Nav/Nav";
import HeaderImg from "./components/Hero/HeaderImg";
import Aboutus from "./components/AboutUs";
import Hero from "./components/Hero/Hero";
import Sponsors from "./components/Sponsorss";
import Blog from "./components/Blog/Blog";
import Footer from "./components/Footer";
import Aim from "./components/Aim";
import Events from "./components/Events";
import PublicEvents from "./components/OthersEvents";
import Partners from "./components/Partnerss";
import Gallery from "./components/Gallery";
import Donation from "./components/Donation";
import Contact from "./components/contact";
import DetailView from "./components/Blog/DetailView";
import DetailViewEvents from "./components/Events/DetailEventView";
import DetailViewEventsPublic from "./components/OthersEvents/DetailEventView";
import Project from "./components/Projects";
import XEvents from "./components/Events/x";
import EventSponsors from "./components/Sponsorss/eventSponsors";
import EventPhotos from "./components/Gallery/index";
import EventVideos from "./components/Gallery/eventvideos";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

const Routing = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route exact path="/home">
        <Hero />
        <Blog />
      </Route>
      <Route path="/aboutus">
        <Aboutus />
      </Route>
      <Route path="/aim">
        <Aim />
      </Route>
      <Route path="/events">
        <Events />
      </Route>
      <Route exact path="/eventssample" component={Events}></Route>
      <Route exact path="/eventsothers" component={PublicEvents}></Route>

      <Route exact path="/eventvideos" component={EventVideos}></Route>
      <Route path="/publicevents">
        <PublicEvents />
      </Route>
      <Route path="/partners">
        <Partners />
      </Route>
      <Route path="/gallery">
        <Gallery />
      </Route>
      <Route path="/sponsors">
        <Sponsors />
      </Route>
      <Route path="/eventsponsors">
        <EventSponsors />
      </Route>
      <Route path="/donation">
        <Donation />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/project">
        <Project />
      </Route>
      <Route path="/blog/:id" component={DetailView}></Route>
      <Route path="/projects/:id" component={DetailView}></Route>
      <Route path="/eventsview/:id" component={DetailViewEvents}></Route>
      <Route
        path="/eventsviewpublic/:id"
        component={DetailViewEventsPublic}
      ></Route>
    </Switch>
  );
};

const App = () => {
  return (
    <div className="App">
      <BrowserRouter forceRefresh>
        <Nav />
        <HeaderImg />
        <Routing />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
