/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const Overlay = () => <div css={styles} className="overlay"></div>;

const styles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  /* background: #3f52e3; */
  @media (max-width: 1010px) {
    display: none;
  }
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 875px) {
    display: none;
  }
`;

export default Overlay;
