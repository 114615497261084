/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Container from "../GlobalComponents/Container";
import Title from "../GlobalComponents/Title";

const Testimonials = () => {
  return (
    <section css={styles} className="testimonials">
      <Container>
        <div className="testimonialHeading">
          <Title title="AIM !" />
        </div>
        <div className="aboutuscontent">
          <h4>THE AFTERMATH - THE TSUNAMI AND THE WAR</h4>
          <p>
            The Civil War between the Government of Sri Lanka and the Tamil
            Tigers came to a climax in 2009. The War took its Toll of Human
            Lives. As a result of the War , Thousands of Women lost their Men
            Folk – Husbands , Brothers and Fathers. Many Women became Heads of
            Households overnight after the Tsunami and then the war, requiring
            them to search for employment in order to earn a living. However ,
            there were no employment opportunities as employers were not willing
            to invest in a War Torn area.
          </p>
          <p>
            A majority of the affected people were Women and Children. It was
            very obvious that a generation of Children were going to be
            affected. Their education had been hampered since 2000, and there
            was yet no solution in sight to the educational dreams of the
            Children or a complete end to the misery and agony of thousands of
            Women and Children
          </p>
          <p>
            <b>
              CONCERN SRI LANKA FOUNDATION’S ROLE CONCERN SRI LANKA FOUNDATION
            </b>{" "}
            is a charity, Non Governmental, non profit organisation which was
            established in United Kingdon in 2007 to assist disadvantaged
            children and their families in Sri Lanka, especially in the the
            North and East Province. CSLF is a non political entity and provides
            equal opportunity and access to education to all disadvantaged
            children who seek our services, and does not discriminate on the
            basis of Religion, Caste , Creed, Gender or any other factor.
          </p>
          <p>
            CSLF Non Profit Organisation, (Registered Charity in England and
            Wales no 1153369) has been, supporting & working with, and speaking
            for, the most disadvantaged & vulnerable children and Youth & their
            families in the North and East of Sri Lanka. We have been directly
            involved in providing support services to children, youth & their
            families who have been affected by the war and the Tsunami. We are
            Collobrating with registered organization such as “Lions club" and
            “AEDU” in North and East of Sri Lanka to deliver the Projects. Like
            similar Social Service oriented organizations of its kind, GSLF is
            committed to implementing a programme to help unfortunate War Widows
            and other affected Women.
          </p>
        </div>
      </Container>
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 110px 0;
  .aboutuscontent {
    h4 {
      padding: 30px 0 0 0;
      font-weight: 800;
      color: #000;
      font-size: 18px;
    }
    h5 {
      font-weight: 600;
      color: #72789a;
    }

    ul {
      margin-left: 50px;
    }
    li {
      text-align: left;
      font-size: 17px;
      color: #72789a;
      font-weight: 500;
      line-height: 1.7;
    }
    p {
      padding: 30px 0 0 0;
      color: #72789a;
      font-weight: 500;
      line-height: 1.7;
      letter-spacing: 0.05em;
      font-size: 16px;
    }
  }

  .testimonialHeading {
    text-align: left;
    margin-top: 100px;
    .title {
      text-align: center;
      font-size: 38px;
      text-align: left;
      padding: 30px 0 0 0;
      color: #212122;
    }
    p {
      padding: 30px 0 0 0;
      color: #72789a;
      font-weight: 700;
      line-height: 1.7;
      font-size: 17px;
    }
    h4 {
      padding: 30px 0 0 0;
      color: #212122;
      font-weight: 700;
      line-height: 1.7;
      font-size: 27px;
    }
  }
  @media (max-width: 690px) {
    .aboutuscontent {
      p {
        font-size: 15px;
        padding: 0 8px;
        margin: 10px 0;
        font-weight: 600;
        br {
          display: none;
        }
      }
      h4 {
        font-size: 16px;
        padding: 0 8px;
        margin: 10px 0;
        color: #212122;
      }
      h5 {
        font-size: 18px;
        padding: 0 8px;
        margin: 10px 0;
      }
    }

    .testimonialHeading {
      margin-top: -30px;
      padding: -30px 0 0 0;
      p {
        font-size: 14px;
        padding: 0 0px;
        margin: 0px 0;
        text-align: justify;

        br {
          display: none;
        }
      }
      h4 {
        font-size: 28px;
        padding: 0 8px;
        margin: 10px 0;
        text-align: left;
      }
    }
  }

  @media (max-width: 1175px) {
    .container {
      max-width: 90%;
    }
  }

  /* xtra large screen */
  @media (min-width: 1920px) {
    width: 100%;
    padding: 140px 0;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 110px 0;
  }
`;

export default Testimonials;

/*
 <div className="aboutuscontent">
          <h4>What is Our Mission?</h4>
          We aims are ,
          <br /> 1.to improve the education <br />
          2.to improve the health <br />
          3.to support one parent families <br />
          4.to support orphants
          <p>
            To improve the welfare of the people of the in Sri Lanka by:
            Assessing their needs. Highlighting the state of health. Harnessing
            the expertise of the Expatriate Tamil Community. Raise funds &
            channel resources to meet the identified needs.
          </p>
          <br />
          <h4>What are our Objectives?</h4>
          1.to support the development of the infrastructure.
          <br />
          2.to develop & fund job opportunity projects <br />
          3.to facilitate the Health Care seminars at schools.
          <br />
          <br />
          <h4>Want to be a Donor of CSLF?</h4>
          CSLF’s donorship will be open to all who subscribe to the objectives
          of the Organization.
        </div>

*/
