/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Container from "../GlobalComponents/Container";
import Title from "../GlobalComponents/Title";

const Testimonials = () => {
  return (
    <section css={styles} className="testimonials">
      <Container>
        <div className="testimonialHeading">
          <Title title="Contact Us !" />
        </div>
        <div className="aboutuscontent">
          <h4>Please write to:</h4>
          <ul>
            <li>concernsrilanka@gmail.com </li>
            <li>admin@concernsrilanka.org </li>
          </ul>
          <h4>Address:</h4>
          <ul>
            <li>
              Concern Srilanka Foundation Limited <br />
              119 Harlington Road
              <br /> Uxbridge
              <br /> UB8 3JA{" "}
            </li>
          </ul>
          <h4>Contact No: </h4>
          <ul>
            <li>07534 284 858</li>
          </ul>
        </div>
      </Container>
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 100px 0;

  @media (min-width: 1920px) {
    width: 100%;
    padding: 140px 0;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 110px 0;
  }
  .aboutuscontent {
    h4 {
      padding: 30px 0 0 0;
      font-weight: 800;
      color: #000;
      font-size: 22px;
    }
    h5 {
      font-weight: 600;
      color: #72789a;
    }

    ul {
      margin-left: 50px;
    }
    li {
      text-align: left;
      font-size: 20px;
      color: #72789a;
      font-weight: 500;
      line-height: 1.7;
    }
    p {
      padding: 30px 0 0 0;
      color: #72789a;
      font-weight: 700;
      line-height: 2.2;
      font-size: 17px;
    }
  }

  .testimonialHeading {
    text-align: left;
    margin-top: 100px;
    .title {
      text-align: center;
      font-size: 35px;
      text-align: left;
      padding: 30px 0 0 0;
      color: #212122;
    }
    p {
      padding: 30px 0 0 0;
      color: #72789a;
      font-weight: 700;
      line-height: 1.7;
      font-size: 17px;
    }
    h4 {
      padding: 30px 0 0 0;
      color: #212122;
      font-weight: 700;
      line-height: 1.7;
      font-size: 27px;
    }
  }
  @media (max-width: 690px) {
    .aboutuscontent {
      p {
        font-size: 15px;
        padding: 0 8px;
        margin: 10px 0;
        font-weight: 600;
        br {
          display: none;
        }
      }
      h4 {
        font-size: 16px;
        padding: 0 8px;
        margin: 10px 0;
        color: #212122;
      }
      h5 {
        font-size: 18px;
        padding: 0 8px;
        margin: 10px 0;
      }
    }

    .testimonialHeading {
      margin-top: -30px;
      padding: -30px 0 0 0;
      p {
        font-size: 14px;
        padding: 0 0px;
        margin: 0px 0;
        text-align: justify;

        br {
          display: none;
        }
      }
      h4 {
        font-size: 28px;
        padding: 0 8px;
        margin: 10px 0;
        text-align: left;
      }
    }
  }

  @media (max-width: 1175px) {
    .container {
      max-width: 90%;
    }
  }
`;

export default Testimonials;

/*
 <div className="aboutuscontent">
          <h4>What is Our Mission?</h4>
          We aims are ,
          <br /> 1.to improve the education <br />
          2.to improve the health <br />
          3.to support one parent families <br />
          4.to support orphants
          <p>
            To improve the welfare of the people of the in Sri Lanka by:
            Assessing their needs. Highlighting the state of health. Harnessing
            the expertise of the Expatriate Tamil Community. Raise funds &
            channel resources to meet the identified needs.
          </p>
          <br />
          <h4>What are our Objectives?</h4>
          1.to support the development of the infrastructure.
          <br />
          2.to develop & fund job opportunity projects <br />
          3.to facilitate the Health Care seminars at schools.
          <br />
          <br />
          <h4>Want to be a Donor of CSLF?</h4>
          CSLF’s donorship will be open to all who subscribe to the objectives
          of the Organization.
        </div>

*/
