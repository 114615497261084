import { useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Title from "../GlobalComponents/Title";
import Container from "../GlobalComponents/Container";
import ServiceCard from "./PartnersCard";
import partnersdata from "../../mocks/partners";
const Services = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(partnersdata);
  });

  console.log(data);
  return (
    <section css={styles} className="services">
      <Title title="Our Partners" />
      <Container>
        {data.map((item) => (
          <ServiceCard serviceTitle={item.name} img={item.image} />
        ))}
      </Container>
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 110px 0;
  background: #fafafa;

  @media (min-width: 1920px) {
    width: 100%;
    padding: 140px 0;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 110px 0;
  }

  .title {
    margin-top: 180px;
    color: #0d3c61;
    font-size: 35px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 0 0;
  }

  @media (max-width: 868px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  }
  @media (min-width: 869px) and (max-width: 1175px) {
    .container {
      max-width: 766px;
    }
  }
`;

export default Services;
