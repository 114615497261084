import React, { useState, useEffect } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Overlay from "../GlobalComponents/Overlay";
import Container from "../GlobalComponents/Container";
import Ticker from "./TickerTest";
import Ticker2 from "./TickerTest2";
import Carousel from "./Carousel";

const Hero = () => {
  const [visible, setVisible] = useState(false);
  const [ticker1data, setTicker1Data] = useState(
    "Apoorava Raagangal 2019 on 5th October 2019 at Beck Theatre, Hayes. Further detail contact 07534 284858   |  Phase 2 Some Apoorava Raagangal 2019 on 5th October 2019 at Beck Theatre, Hayes. Further detail contact 07534 284858"
  );
  const [ticker2data, setTicker2Data] = useState(
    "To run, workshop,programme, seminars etc., for the deducated unemployed and the rural youth in co-ordination with other organizations.  |  Phase 2  To run, workshop,programme, seminars etc., for the deducated unemployed and the rural youth in co-ordination with other organizations. "
  );

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 5000);
  });
  return (
    <section css={styles} className="hero" id="home">
      <Overlay />
      <Ticker data={ticker1data} />
      <Container>
        <Carousel />
      </Container>
      <Ticker2 data={ticker2data} style={{ width: 300 }} />
    </section>
  );
};

const styles = css`
  width: 100%;
  min-height: 80vh;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  /* xtra large screen */
  @media (min-width: 1920px) {
    min-height: 80vh;
    padding: 0px 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
    &:nth-child(3) {
      justify-content: flex-end;
    }
  }

  /* large screen */
  @media (max-width: 1366px) {
    padding: 0px 0;
    min-height: 80vh;
    zoom: 88%;
  }

  @media (max-width: 1175px) {
    .container {
      &:nth-child(3) {
        justify-content: flex-start;
      }
    }
  }
`;

export default Hero;
