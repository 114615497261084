/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const Container = ({ children }) => (
  <div css={styles} className="container">
    {children}
  </div>
);

const styles = css`
  width: 100%;
  overflow-x: hidden;
  max-width: 1175px;
  margin: 0 auto;
`;

export default Container;
