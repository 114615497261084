import { useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import Container from "../GlobalComponents/Container";
import BlogCard from "./BlogCard";
import Title from "../GlobalComponents/Title";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactBnbGallery from "react-bnb-gallery";
import axios from "axios";
import BASE_URL from "../../constant";
import Spinner from "react-bootstrap/Spinner";
const Index = (props) => {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [alldata, setAllData] = useState([]);
  const [data, setData] = useState([]);
  let { id } = useParams();
  useEffect(() => {
    axios.get(`${BASE_URL}/all_projects`).then((response) => {
      setAllData(response.data.all_projects);
      setLoading(false);
    });

    alldata.map((item) => {
      if (item._id == id) {
        setData(item);
      }
    });
  }, [alldata]);

  console.log(data);

  return (
    <section css={styles} className="testimonials">
      {loading ? (
        <Container>
          <div
            class="wrapper"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 100,
            }}
          >
            <Spinner
              animation="grow"
              role="status"
              style={{
                padding: 70,
                alignItems: "center",
                justifyContent: "center",
                color: "gray",
                marginLeft: "50%",
              }}
            />
            <br />
            <p style={{ color: "gray", marginTop: 10 }}>
              rendering Data Please wait
            </p>
          </div>
        </Container>
      ) : (
        <Container>
          <div class="wrapper">
            {alldata.map((item) =>
              item._id === id ? (
                <div>
                  <img src={item.profileImg} alt={item._id} className="star" />
                  <span className="titlex"># {item.name}</span> <br />
                  <span className="datex"># {item.date}</span>
                  <div
                    className="descriptionView"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  <br />
                  {item.imgCollection.slice(0, 10).map((subitem) => {
                    return (
                      <img
                        className="tileimage"
                        src={subitem}
                        style={{
                          height: 70,
                          width: 70,
                          borderRadius: 5,
                          margin: 3,
                        }}
                      />
                    );
                  })}
                  <button
                    className="btn"
                    onClick={() => {
                      setIsOpen(true);
                      setImages(item.imgCollection);
                    }}
                  >
                    View in gallery
                  </button>
                </div>
              ) : (
                <div></div>
              )
            )}
          </div>
          <div className="blogInfo">
            <Title title="Recent Other posts !" />
          </div>

          <Container>
            {alldata.slice(0, 6).map((item) => (
              <Link style={{ textDecoration: "none" }} to={`/blog/${item._id}`}>
                <BlogCard
                  blogImg={item.profileImg}
                  date={item.date}
                  title={item.name}
                  description={item.description}
                />
              </Link>
            ))}
          </Container>
          <ReactBnbGallery
            show={isOpen}
            photos={images}
            onClose={() => setIsOpen(false)}
          />
        </Container>
      )}
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 110px 0;
  flex-direction: row;
  /* xtra large screen */
  @media (min-width: 1920px) {
    width: 100%;
    padding: 140px 0;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 110px 0;
  }
  .btn {
    padding: 30px;
    border-radius: 10px;
    background: linear-gradient(90deg, #ece9e6 0%, #ffffff 100%);
    color: #000;
    font-size: 15px;
    font-weight: 700;
    &:focus {
      outline: 0 !important;
    }
  }
  .leftcontainer {
    flex-basis: 60%;
    padding: 100px;
  }
  .rightcontainer {
    flex-basis: 40%;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 0 0;
  }

  .cardskleton {
    width: 100%;
    max-width: 360px;
    border-radius: 15px;
    box-shadow: 10px 42px 32px #383b470f;
    background: red;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    margin: 20px;
    padding: 20px;
    margin-left: 30px;
  }

  .wrapper {
    column-count: 1;
    column-gap: 50px;
    padding: 50px;
    flex-direction: row-gap;
    .titlex {
      align-items: center;
      justify-content: space-between;
      color: #fff;
      margin: 3px;
      background: #bcdffb;
      background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
      text-decoration: none;
      border-radius: 300px;
      align-items: flex-start;
      font-size: 24px;
      padding: 2px;
      padding-right: 10px;
      padding-left: 10px;
      font-weight: 500;
      transition: color 300ms ease-in-out;
      line-height: 1.8;
    }
    .datex {
      align-items: center;
      justify-content: space-between;
      color: #fff;
      margin: 3px;
      background: #bcdffb;
      background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
      text-decoration: none;
      border-radius: 40px;
      align-items: flex-start;
      font-size: 14px;
      padding: 2px;
      padding-right: 10px;
      padding-left: 10px;
      font-weight: 500;
      transition: color 300ms ease-in-out;
      line-height: 1.8;
    }
  }
  .descriptionView {
    line-height: 1.6;
    font-family: Helvetica;
    text-align: justify;
    margin: 0;
    font-size: 14px;
    line-height: 1.7rem;
  }

  .descriptionView.a {
    word-break: normal;
  }

  .star {
    float: left;
    width: 400px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  /* BLOG CARD */

  .blogCard {
    width: 100%;
    max-width: 360px;

    /* border-radius: 15px; */
    box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 12px 0px;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    /* box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08); */
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      /* transform: scale(1.02); */
      transition: all 0.1s ease-in-out;
    }

    margin: 5px;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 200px;
      display: block;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }
  .blogInfo {
    text-align: center;

    p {
      color: #000;
      font-weight: 900px;
      line-height: 1.7;
      margin: 20px 0 0 0;
    }
    .title {
      margin-top: 30px;

      font-size: 30px;
      background: -webkit-linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn {
    margin: 0 auto;
  }
  .container {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 641px) {
    .btn {
      margin-top: 30px;
      width: 100%;
    }

    .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0px 0 0 0;
    }

    .wrapper {
      width: 100%;
      column-count: 1;
      column-gap: 50px;
      padding: 0px;
      flex-direction: row-gap;
      padding: 20px;
      .tileimage {
        height: 50px;
        width: 50px;
      }
      .titlex {
        align-items: center;
        justify-content: space-between;
        color: #fff;
        margin: 3px;
        background: #bcdffb;
        background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
        text-decoration: none;
        border-radius: 300px;
        align-items: flex-start;
        font-size: 17px;
        padding: 2px;
        padding-right: 10px;
        padding-left: 10px;
        font-weight: 500;
        transition: color 300ms ease-in-out;
        line-height: 1.8;
      }
      .datex {
        align-items: center;
        justify-content: space-between;
        color: #fff;
        margin: 3px;
        background: #bcdffb;
        background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
        text-decoration: none;
        border-radius: 40px;
        align-items: flex-start;
        font-size: 14px;
        padding: 2px;
        padding-right: 10px;
        padding-left: 10px;
        font-weight: 500;
        transition: color 300ms ease-in-out;
        line-height: 1.8;
      }
    }
    .star {
      float: left;
      width: 100%;
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
    }

    .blogInfo {
      p {
        font-size: 15px;
        padding: 0 14px;
        br {
          display: none;
        }
      }
    }
  }
  @media (max-width: 840px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
    .youtube {
      margin-bottom: 10px;
    }
  }
  @media (min-width: 841px) and (max-width: 1175px) {
    .container {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;
export default Index;
