/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const Container = ({ children }) => (
  <div css={styles} className="container">
    {children}
  </div>
);

const styles = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1140px;
  margin: 40;
  justify-content: flex-start;
  @media (max-width: 1175px) {
    max-width: 90%;
  }
`;

export default Container;
