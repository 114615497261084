import Img1 from "../img/partners/01.jpeg";
import Img2 from "../img/partners/02.jpeg";
import Img3 from "../img/partners/03.png";

const Partners = [
  { name: "Puloly Development Foundation", image: Img1 },
  { name: "Lions Club – Trincomalee", image: Img2 },
  { name: "AEDU – Kilinochchi", image: Img3 },
];

export default Partners;
