import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import "react-widgets/dist/css/react-widgets.css";
import { Calendar } from "react-widgets";
import axios from "axios";
import BASE_URL from "../../constant/index";
const Carouselx = () => {
  const [index, setIndex] = useState(0);
  const [date, setDate] = useState(new Date());
  const [alldata, setAllData] = useState([]);
  useEffect(() => {


    axios.get(`${BASE_URL}/all_carousel_data`).then((response) => {
      console.log(response.data.all_carousel_data);
      setAllData(response.data.all_carousel_data);
    });
  });
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  return (
    <div css={styles} className="containercommon">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {alldata.map((item) => (
          <Carousel.Item>
            <img className="imageCarousel" src={item.image} alt="slide" />
          </Carousel.Item>
        ))}
      </Carousel>
      <a
        className="eventBannerContainer"
        href="https://www.youtube.com/watch?v=u39KycUNRDc"
      >
        <img
          className="eventBanner"
          alt="fef"
          src="http://www.concernsrilanka.com/images/albums/pattukku-paatu/pattukku-paatu1531499774.jpg"
        />
      </a>
      <div className="calanderContainer">
        <Calendar
          value={date}
          className="calander"
          onChange={(value) => setDate(value)}
        />
      </div>
    </div>
  );
};

const styles = css`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 110px;
  position: absolute;
  top: 25%;
  left: 0%;
  flex-direction: row;

  @media (min-width: 1920px) {
    max-width: 1530px;
    padding: 160px 20px;
    margin: 0px auto;
    margin-left: 10%;
    margin-right: 20%;
    top: 23%;
  }
  /* xtra large screen */
  @media (min-width: 1920px) {
    .carousel {
      flex: 50%;
      width: 50%;
      height: auto;
      margin: auto;
      border-radius: 30px;
    }
    .imageCarousel {
      width: 100%;
      height: 500px;
      margin-left: 0%;
      border-radius: 3px;
    }
    .eventBannerContainer {
      flex: 25%;
      margin-left: 1%;
    }
    .eventBanner {
      width: 400px;
      height: 500px;
      margin-left: 1.8%;
      border-radius: 3px;
      cursor: pointer;
    }
    .calanderContainer {
      flex: 25%;
      margin-left: 1%;
    }
    .calander {
      width: 400px;
      height: 500px;
      margin-left: 1%;
    }
  }

  /* large screen */
  @media (min-width: 1366px) {
    width: 100%;

    .containercommon {
      padding: 10px 60px;
    }
    .carousel {
      flex: 50%;
      width: 50%;
      height: auto;
      margin: auto;
    }
    .imageCarousel {
      width: 100%;
      height: 350px;
      /* margin-left: 1%; */
    }
    .eventBannerContainer {
      flex-basis: 20%;
      margin-left: 1.3%;
    }
    .eventBanner {
      width: 300px;
      height: 350px;
    }
    .calanderContainer {
      flex-basis: 26.5%;
      margin-left: 1%;
    }
    .calander {
      width: 350px;
      height: 350px;
      margin-left: 1%;
    }
  }

  /* medium screen */
  @media (max-width: 1024px) {
    width: 100%;
    .containercommon {
      padding: 110px 30px;
    }
    .carousel {
      width: 60%;
      height: auto;
      margin: auto;
    }
    .imageCarousel {
      width: 100%;
      height: 350px;
      /* margin-left: 1%; */
      border: 2px solid #1488cc;
    }
    .eventBannerContainer {
      width: 20%;
    }
    .eventBanner {
      width: 300px;
      height: 350px;
      margin-left: 1%;
      border: 2px solid #1488cc;
      cursor: pointer;
    }

    .calander {
      width: 300px;
      height: 350px;
      margin-left: 1%;
      border: 2px solid #1488cc;
    }
  }

  @media (max-width: 875px) {
    /* display: none; */
    width: 120%;
    margin-top: -18%;
    margin-left: -10%;
    margin-bottom: 10%;

    .containercommon {
      width: 120%;
      margin-top: -18%;
      margin-left: -10%;
      margin-bottom: 10%;
    }

    .eventBanner {
      width: 100vw;
      height: 60vh;
      margin: -60%;
      margin-left: -57%;
      margin-top: -50%;
      border: none;
      border-radius: 5px;
    }

    .carousel {
      width: 100%;
      height: 20%;
      margin: auto;
      display: none;
    }
    .imageCarousel {
      width: 100%;
      height: 300px;
      margin-left: 1%;
      border: 2px solid #1488cc;
    }
    .calander {
      display: none;
    }

    .carousel {
      width: 100%;
      height: auto;
      margin: 0px auto;
    }
  }
`;

export default Carouselx;

/*
style={{
        display: "flex",
        alignItems: "flex-start",
        width: "60%",
        height: "auto",
        display: "block",
        position: "absolute",
        top: "64%",
        left: "2%",
        transform: "translateY(-50%)",
      }}

*/
