/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import FadeIn from "react-fade-in";
const BlogCard = ({ blogImg, date, description, title }) => {
  return (
    <FadeIn delay={100} transitionDuration={1000}>
      <div css={styles} className="blogCard">
        <img src={blogImg} alt="blog" />
        <div className="blogCardInfo">
          <div className="blogCardDate">
            {/* <a href="#/">{date}</a> */}
            {/* <a href="#/">Admin</a> */}
            {/* <a href="#/">3</a> */}
          </div>
          <div className="blogCardDesc">
            <span className="title"># {title}</span>

            <br />

            <a href="#/" dangerouslySetInnerHTML={{ __html: description }}></a>
          </div>
        </div>
      </div>
    </FadeIn>
  );
};

const styles = css`
  width: 100%;
  max-width: 360px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 50) 0px 4px 12px 0px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  flex-direction: column;

  &:hover {
    /* transform: scale(1.02);
    transition: all 0.1s ease-in-out; */
  }
  a {
    width: 100%;
  }

  img {
    width: 100%;
    height: 150px;
    display: block;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .title {
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    background:blue;
    padding-right:10px;
    font-weight: 700;
    transition: color 300ms ease-in-out;
    /* line-height: 1.8; */
    letter-spacing: 0.02em;
    text-align:left;
  }
  .read {
    color: #2c2c2c;
    opacity: 0.7;
    /* background: #000; */
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
    align-items: flex-start;
    font-size: 10px;
    padding: 1px;
    float: right;
    margin-right: 20px;
    padding-right: 10px;
    padding-left: 10px;
    font-weight: 700;
    transition: color 300ms ease-in-out;
    line-height: 1.8;
  }
  .blogCardInfo {
    display: flex;
    align-items: flex-start;
    padding: 5px 0;
    margin-left: -100px;
    .blogCardDate {
      width: 100%;
      max-width: 30%;
      display: flex;
      flex-direction: column;
      text-align: right;
      margin-right: 3px;
      a {
        background: #2c2c2c;
        text-decoration: none;
        color: #fff;
        border-radius: 500px;
        font-size: 12px;
        padding: 2px 0 0 0;
        padding: 1px;
        font-weight: 700;
        transition: color 300ms ease-in-out;
        line-height: 2.2;

     
      }
    }
    .blogCardDesc {
      width: 100%;
      max-width: 66%;
      flex-direction: row;
      a {
        color: #72789a;
        font-size: 12px;
        font-weight: 700;
        text-decoration: none;
        transition: color 300ms ease-in-out;
        line-height: 1.7;
        display: inline-block;
        width: 250px;
        overflow: hidden;
        white-space: wrap;
        text-overflow: ellipsis;
        height: 3.3em;
        margin-top: 10px;

        &:hover {
          /* color: #005aa7; */
        }
      }
    }
  }
  @media (max-width: 840px) {
    max-width: 400px;
    margin-bottom: 10px;
  }
  @media (min-width: 841px) and (max-width: 1175px) {
    max-width: 45.5%;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

export default BlogCard;
