/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import HeroImg from "../../img/x.jpg";

const HeaderImg = () => (
  <div
    style={{
      backgroundColor: "#f5f7fa",
      boxShadow: `0 1px 6px 0 rgba(32, 33, 36, 0.28);`,
    }}
  >
    <img css={styles} src={HeroImg} alt="heroBanner" />
  </div>
);

const styles = css`
  width: 86%;
  position: absolute;
  margin-left: 7%;
  background-size: cover;
  background-position: center;
  margin-top: 2.8%;
  height: 18.5%;
  left: 0;

  /* large screen */
  @media (min-width: 1366px) {
    margin-top: 3.55%;
  }
  @media (min-width: 1920px) {
    margin-top: 2.85%;
  }
  @media (max-width: 875px) {
    display: none;
    padding: -100px;
  }
  @media (min-width: 876px) and (max-width: 1175px) {
    right: 0;
    left: auto;
    width: 50%;
    display: none;
  }
`;

export default HeaderImg;
