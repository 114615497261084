import React, { useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import uplinkdata from "../../mocks/EventsTodisplay";
import Container from "../GlobalComponents/Container";
import Data from "../../mocks/EventsTodisplay";
import BlogCard from "./EventsCard";
import { Link } from "react-router-dom";
import Title from "../GlobalComponents/Title";
import ReactBnbGallery from "react-bnb-gallery";
import Button from "../GlobalComponents/Button";
import axios from "axios";
import BASE_URL from "../../constant";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router";
const Index = (props) => {
  const [post, setAllPost] = useState([]);
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  let { id } = useParams();
  useEffect(() => {
    axios.get(`${BASE_URL}/ourevents`).then((response) => {
      console.log(response.data.ourevents);
      setAllPost(response.data.ourevents);
      setLoading(false);
    });

    post.map((item) => {
      if (item._id == id) {
        setData(item);
      }
    });
  }, [post]);

  return (
    <section css={styles} className="testimonials">
      {loading ? (
        <Container>
          <div
            class="wrapper"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 100,
            }}
          >
            <Spinner
              animation="grow"
              role="status"
              style={{
                padding: 70,
                alignItems: "center",
                justifyContent: "center",
                color: "gray",
                marginLeft: "50%",
              }}
            />
            <br />
            <p style={{ color: "gray", marginTop: 10 }}>
              rendering Data Please wait
            </p>
          </div>
        </Container>
      ) : (
        <Container>
          <div class="wrapper">
            {post.map((item) =>
              item._id === id ? (
                <div>
                  {!item.description ? (
                    <img
                      src={item.cover_image}
                      alt={item.title}
                      className="star2"
                    />
                  ) : (
                    <img
                      src={item.cover_image}
                      alt={item.title}
                      className="star"
                    />
                  )}
                  <span className="titlex"># {item.title}</span> <br />
                  <span className="datex"># {item.date}</span>
                  <div
                    className="descriptionView"
                    style={{
                      height: 700,
                      overflow: "hidden",
                      overflowY: "scroll",
                    }}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  {!item.description ? (
                    <div className="cardWrappercol">
                      {/* Check Organizers available */}
                      {!item.organisers ? (
                        <div></div>
                      ) : (
                        <div className="cardEventsnull">
                          <span className="datex">Organizers</span>
                          <br />
                          {item.organisers}
                        </div>
                      )}
                      {/* Check Location available */}
                      {!item.location ? (
                        <div></div>
                      ) : (
                        <div className="cardEventsnull">
                          <span className="datex">Location</span>
                          <br />
                          {item.location}
                        </div>
                      )}
                      {/* Check date available */}
                      {!item.date ? (
                        <div></div>
                      ) : (
                        <div className="cardEventsnull">
                          <span className="datex">Date</span>
                          <br />
                          {item.date}
                        </div>
                      )}
                      {/* Check time available */}
                      {!item.time ? (
                        <div></div>
                      ) : (
                        <div className="cardEventsnull">
                          <span className="datex">Time</span>
                          <br />
                          {item.time}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="cardWrapper">
                      {/* Check Organizers available */}
                      {!item.organizers ? (
                        <div></div>
                      ) : (
                        <div className="cardEvents">
                          <span className="datex">Organizers</span>
                          <br />
                          <div
                            style={{ padding: 10 }}
                            dangerouslySetInnerHTML={{
                              __html: item.organizers,
                            }}
                          ></div>
                        </div>
                      )}
                      {/* Check Location available */}
                      {!item.location ? (
                        <div></div>
                      ) : (
                        <div className="cardEvents">
                          <span className="datex">Location</span>
                          <br />
                          {item.location}
                        </div>
                      )}
                      {/* Check date available */}
                      {!item.date ? (
                        <div></div>
                      ) : (
                        <div className="cardEvents">
                          <span className="datex">Date</span>
                          <br />
                          {item.date}
                        </div>
                      )}
                      {/* Check time available */}
                      {!item.time ? (
                        <div></div>
                      ) : (
                        <div className="cardEvents">
                          <span className="datex">Time</span>
                          <br />
                          {item.time}
                        </div>
                      )}
                    </div>
                  )}
                  <br />
                  <div className="cardWrapper">
                    {/* Check ticketstore available */}
                    {!item.ticketStores ? (
                      <div></div>
                    ) : (
                      <div className="cardEventsstore">
                        <span className="datex">Ticket Stores</span>
                        <br />
                        <div
                          className="descriptionView"
                          dangerouslySetInnerHTML={{
                            __html: item.ticketStores,
                          }}
                        />
                      </div>
                    )}

                    {/* Check seatingplan available */}
                    {!item.seatingPlan ? (
                      <div></div>
                    ) : (
                      <div className="cardEventsstore">
                        <span className="datex">Seating Plan</span>
                        <br />
                        <img
                          src={item.seatingPlan}
                          style={{ width: 280, height: 280 }}
                          alt="Seating Plan Prepaing process"
                        />
                      </div>
                    )}

                    {/* Check google-location available */}
                    {!item.locationMap ? (
                      <div></div>
                    ) : (
                      <div
                        className="cardEventsstore"
                        style={{ width: 600, maxWidth: 900 }}
                      >
                        <span className="datex">Location in Map </span>
                        <br />
                        <iframe
                          src={item.locationMap}
                          width="600"
                          height="450"
                          frameborder="0"
                          style={{ width: 500, height: 450 }}
                          allowfullscreen=""
                          aria-hidden="false"
                          tabindex="0"
                        ></iframe>
                      </div>
                    )}
                  </div>
                  <div className="blogInfo">
                    <Title title="Event sponsors >" />
                  </div>
                  <div className="sponsorsection">
                    {item.sponsorsnew.slice(0, 10).map((subitem) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: 3,
                          }}
                        >
                          <img
                            className="tileimage"
                            src={subitem.sponsor_img}
                            className="imgSponsors"
                          />
                          <span className="sponsorname">
                            {subitem.sponsorname}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div></div>
              )
            )}
          </div>
        </Container>
      )}
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 110px 0;
  flex-direction: row;

  /* xtra large screen */
  @media (min-width: 1920px) {
    width: 100%;
    padding: 140px 0;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 110px 0;
  }

  .btn {
    padding: 30px;
    border-radius: 10px;
    background: linear-gradient(90deg, #ece9e6 0%, #ffffff 100%);
    color: #000;
    font-size: 15px;
    font-weight: 700;
    &:focus {
      outline: 0 !important;
    }
  }
  .leftcontainer {
    flex-basis: 60%;
    padding: 100px;
  }
  .rightcontainer {
    flex-basis: 40%;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 0 0;
  }

  .cardskleton {
    width: 100%;
    max-width: 360px;
    border-radius: 15px;
    box-shadow: 10px 42px 32px #383b470f;
    background: red;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    margin: 20px;
    padding: 20px;
    margin-left: 30px;
  }

  .wrapper {
    column-count: 1;
    column-gap: 30px;
    padding: 50px;
    flex-direction: row-gap;

    .cardWrapper {
      display: flex;
      flex-direction: row-gap;
      .cardEvents {
        padding: 10px;
        max-width: 270px;
        border-radius: 10px;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
        overflow-wrap: break-word;
        margin: 5px;
        color: #000;
        font-weight: 300;
        text-align: center;
        .datex {
          padding: 0px 80px 2px 80px;
        }
      }

      .cardEventsstore {
        padding: 10px;
        max-width: 400px;
        border-radius: 10px;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
        overflow-wrap: break-word;
        margin: 5px;
        color: #000;
        font-weight: 300;
        text-align: center;

        .datex {
          padding: 0px 100px 2px 100px;
        }
        p {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    .cardWrappercol {
      display: flex;
      flex-direction: column;
      .cardEventsnull {
        padding: 20px;
        max-width: 500px;
        border-radius: 10px;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
        overflow-wrap: break-word;
        margin: 5px;
        color: #000;
        font-weight: 400;
        font-size: 14px;
        text-align: center;

        .datex {
          padding: 0px 80px 2px 80px;
        }
      }
    }
    .sponsorsection {
      display: flex;
      flex-direction: row;
      /* overflow-x: scroll;
      scroll-behavior: smooth; */
    }
    .titlex {
      align-items: center;
      justify-content: space-between;
      color: #fff;
      margin: 3px;
      background: #bcdffb;
      background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
      text-decoration: none;
      border-radius: 300px;
      align-items: flex-start;
      font-size: 24px;
      padding: 2px;
      padding-right: 10px;
      padding-left: 10px;
      font-weight: 500;
      transition: color 300ms ease-in-out;
      line-height: 1.8;
    }
    .datex {
      align-items: center;
      justify-content: space-between;
      color: #fff;
      margin: 3px;
      background: #bcdffb;
      background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
      text-decoration: none;
      border-radius: 40px;
      align-items: flex-start;
      font-size: 14px;
      padding: 2px;
      padding-right: 10px;
      padding-left: 10px;
      font-weight: 500;
      transition: color 300ms ease-in-out;
      line-height: 1.8;
    }
    .sponsorname {
      align-items: center;
      justify-content: space-between;
      color: #fff;
      margin: 3px;
      background: #1488cc;
      /* background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%); */
      text-decoration: none;
      border-radius: 40px;
      align-items: flex-start;
      font-size: 8px;
      padding: 2px;
      padding-right: 10px;
      padding-left: 10px;
      font-weight: 500;
      transition: color 300ms ease-in-out;
      line-height: 1.8;
    }
    .imgSponsors {
      width: 90px;
      height: 80px;
      margin: 3px;
      border-radius: 10px;
      padding: 10px;
    }
  }
  .descriptionView {
    line-height: 1.6;
    font-family: Helvetica;
    text-align: justify;
    margin: 0;
    font-size: 14px;
    line-height: 1.7rem;

    iframe {
      height: 300px;
      width: 300px;
    }
  }

  .descriptionView.a {
    word-break: normal;
  }

  .star {
    float: left;
    width: 500px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .star2 {
    float: left;
    width: 600px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .sponsors {
    display: flex;
    flex-direction: row;
    column-count: 3;
    column-gap: 30px;
    padding: 50px;
    flex-direction: row-gap;
    .titlexx {
      align-items: center;
      justify-content: space-between;
      color: #fff;
      margin: 3px;
      background: #bcdffb;
      background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
      text-decoration: none;
      border-radius: 300px;
      align-items: flex-start;
      font-size: 14px;
      padding: 2px;
      padding-right: 10px;
      padding-left: 10px;
      font-weight: 500;
      transition: color 300ms ease-in-out;
      line-height: 1.8;
    }
  }

  /* BLOG CARD */

  .blogCard {
    width: 100%;
    max-width: 360px;

    /* border-radius: 15px; */
    box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 12px 0px;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    /* box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08); */
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      /* transform: scale(1.02); */
      transition: all 0.1s ease-in-out;
    }
    margin: 5px;
    margin-bottom: 20px;
  }
  .blogInfo {
    text-align: center;

    p {
      color: #000;
      font-weight: 900px;
      line-height: 1.7;
      margin: 20px 0 0 0;
    }
    .title {
      margin-top: 30px;

      font-size: 30px;
      background: -webkit-linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn {
    margin: 0 auto;
  }
  .container {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 641px) {
    .btn {
      margin-top: 30px;
      width: 100%;
    }

    .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0px 0 0 0;
    }

    .wrapper {
      width: 100%;
      column-count: 1;
      column-gap: 50px;
      padding: 0px;
      flex-direction: row-gap;
      padding: 20px;

      .cardWrapper {
        display: flex;
        flex-direction: column;

        .cardEvents {
          padding: 10px;
          max-width: 400px;
          border-radius: 10px;
          box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
          overflow-wrap: break-word;
          margin: 5px;
          color: #000;
          font-weight: 400;
          font-size: 14px;
          text-align: center;

          .datex {
            padding: 0px 80px 2px 80px;
          }
        }
      }
      .sponsorname {
        align-items: center;
        justify-content: space-between;
        color: #fff;
        margin: 3px;
        background: #1488cc;
        /* background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%); */
        text-decoration: none;
        border-radius: 40px;
        align-items: flex-start;
        font-size: 5px;
        padding: 2px;
        padding-right: 10px;
        padding-left: 10px;
        font-weight: 500;
        transition: color 300ms ease-in-out;
        line-height: 1.8;
      }
      .tileimage {
        height: 50px;
        width: 50px;
      }
      .titlex {
        align-items: center;
        justify-content: space-between;
        color: #fff;
        margin: 3px;
        background: #bcdffb;
        background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
        text-decoration: none;
        border-radius: 300px;
        align-items: flex-start;
        font-size: 14px;
        padding: 2px;
        padding-right: 10px;
        padding-left: 10px;
        font-weight: 500;
        transition: color 300ms ease-in-out;
        line-height: 1.8;
      }
      .datex {
        align-items: center;
        justify-content: space-between;
        color: #fff;
        margin: 3px;
        background: #bcdffb;
        background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
        text-decoration: none;
        border-radius: 40px;
        align-items: flex-start;
        font-size: 10px;
        padding: 2px;
        padding-right: 10px;
        padding-left: 10px;
        font-weight: 500;
        transition: color 300ms ease-in-out;
        line-height: 1.8;
      }
      .imgSponsors {
        width: 50px;
        height: 50px;
        margin: 3px;
        border-radius: 10px;
      }
    }
    .star {
      float: left;
      width: 100%;
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
    }
    .star2 {
      float: left;
      width: 100%;
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
    }

    .blogInfo {
      p {
        font-size: 15px;
        padding: 0 14px;
        br {
          display: none;
        }
      }
    }
  }
  @media (max-width: 840px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
    .youtube {
      margin-bottom: 10px;
    }
  }
  @media (min-width: 841px) and (max-width: 1175px) {
    .container {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;
export default Index;
