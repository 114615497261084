import React, { useState, useEffect } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
// import Link from "./Link";
import { Link } from "react-router-dom";
import { useHistory, NavLink } from "react-router-dom";

const Menu = ({ showMenu, setShowMenu, props }) => {
  const currentRoute = useHistory().location.pathname.toLowerCase();
  // alert(currentRoute);

  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <div css={styles} className={(showMenu ? "hidden" : "") + " menu"}>
      <NavLink
        activeClassName="active"
        className={"tab"}
        to="/home"
        style={styleDecor}
      >
        Home
      </NavLink>
      <NavLink
        activeClassName="active"
        className={"tab"}
        to="/aboutus"
        style={styleDecor}
        showMenu={setShowMenu}
      >
        Aboutus
      </NavLink>
      <NavLink
        activeClassName="active"
        className={"tab"}
        to="/aim"
        style={styleDecor}
        showMenu={setShowMenu}
      >
        Aim/Objective
      </NavLink>
      <NavLink
        activeClassName="active"
        className={"tab"}
        to="/project"
        style={styleDecor}
        setShowMenu={setShowMenu}
      >
        Projects
      </NavLink>
      <NavLink
        activeClassName="active"
        className={"tab"}
        to="/events"
        style={styleDecor}
        refresh={true}
      >
        Events
      </NavLink>
      <NavLink
        activeClassName="active"
        className={"tab"}
        to="/partners"
        style={styleDecor}
        setShowMenu={setShowMenu}
      >
        Partners
      </NavLink>
      <NavLink
        activeClassName="active"
        className={"tab"}
        to="/gallery"
        style={styleDecor}
      >
        Photos & Videos
      </NavLink>
      <NavLink
        activeClassName="active"
        className={"tab"}
        to="/sponsors"
        style={styleDecor}
      >
        Sponsors
      </NavLink>
      <NavLink
        activeClassName="active"
        className={"tab"}
        to="/donation"
        style={styleDecor}
      >
        Donation
      </NavLink>

      <NavLink
        activeClassName="active"
        className={"tab"}
        to="/contact"
        style={styleDecor}
      >
        Contact
      </NavLink>
      {/*

 
      <Link
        activeLink={activeLink}
        setActiveLink={setActiveLink}
        linkName="Public Events"
        linkHref="/publicevents"
        setShowMenu={setShowMenu}
      />
     */}
      {/* <Button btnText="Donate here 💰"></Button> */}
    </div>
  );
};

const styleDecor = {
  textDecoration: "none",
};

const styles = css`
  margin: 0px auto;
  padding-left: 8%;

  .tab {
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    font-weight: bolder;
    color: #fff;
    text-decoration: none;
    letter-spacing: 0.06em;
    display: inline-block;
    padding: 16.5px 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    border-right: 1px solid red;
    border-left: 1px solid red;
    overflow-y: hidden;
    margin-bottom: -7px;

    &:hover {
      color: #fff;
      background-color: #fc466b;
      background-color: #4ac29a;
    }
  }
  .tab.active {
    color: #fff;
    background-color: #182848;
  }

  @media (max-width: 1366px) {
    .tab {
      padding: -20px;
    }
  }

  @media (max-width: 1010px) {
    width: 100%;
    max-width: 80%;
    min-height: 100vh;
    padding: 20px;
    position: absolute;
    top: 0;
    left: -700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    /* background: rgba(0, 0, 0, 0.9); */
    background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
    border-bottom-right-radius: 100px;
    .tab {
      border: 1px solid transparent;
    }
    opacity: 0;
    transition: all 500ms ease-in-out;
    &.hidden {
      opacity: 1;
      left: 0;
    }
    .btn {
      margin: 10px 0 0 0;
    }
    a {
      color: #000;
    }
  }
`;

export default Menu;
