import { useEffect, useState } from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Title from "../GlobalComponents/Title";
import Container from "../GlobalComponents/Container";
import ServiceCard from "./SponsorsCard";
import X from "./x";
import axios from "axios";
import BASE_URL from "../../constant";

const Services = () => {
  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api_refresh();
  });

  const api_refresh = () => {
    axios.get(`${BASE_URL}/event_sponsors`).then((response) => {
      console.log(response.data.eventsponsors);
      setAllData(response.data.eventsponsors);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    });
  };

  return (
    <section css={styles} className="services">
      <X />
      <Title title="Event Sponsors" />
      <Container>
        {alldata.map((item) => (
          <ServiceCard
            serviceTitle={item.name}
            img={item.image}
            link={item.officiallink}
            description={item.description}
          />
        ))}
      </Container>
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 110px 0;
  background: #fafafa;

  @media (min-width: 1920px) {
    width: 100%;
    padding: 140px 0;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 110px 0;
  }

  .title {
    margin-top: 120px;
    color: #0d3c61;
    font-size: 35px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 0 0;
  }

  @media (max-width: 868px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
    .title {
      margin-top: -10px;
    }
  }
  @media (min-width: 869px) and (max-width: 1175px) {
    .container {
      max-width: 766px;
    }
  }
`;

export default Services;
