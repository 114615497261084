/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const Logo = () => <h2 css={styles}>Concern Srilanka Foundation</h2>;

const styles = css`
  display: none;
  font-size: 10px;
  line-height: 1;
  font-weight: 700;
  color: transparent;
  cursor: pointer;
  margin-left: 30px;

  h2 {
    color: transparent;
    display: none;
  }

  @media (max-width: 1100px) {
    font-size: 17px;
    padding: 0px;
    line-height: 1.5;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    display: inline-block;
  }
`;

export default Logo;
