import { useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import axios from "axios";
import BASE_URL from "../../constant/index";

const HeroBg = () => {
  const [alldata, setAllData] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}/all_ticker2`).then((response) => {
      console.log(response.data.all_ticker);
      setAllData(response.data.all_ticker);
    });

    console.log(`#########${alldata}`);
  });
  return (
    <div css={styles} className="tickermain">
      <div className="all">
        <div class="ticker-wrap">
          <div class="ticker">
            {alldata.map((item) => (
              <div class="ticker__item">{item.name}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = css`
  width: 85vw;

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .all {
    width: 85vw;
    margin-left: 8%;
    margin-right: 80%;
  }
  .ticker-wrap {
    position: absolute;
    height: 4rem;
    padding-right: 0%;
    box-sizing: content-box;
    margin-left: 8%;
    margin: 0px auto;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 2px;
    width: 100%;
    color: #000;
    cursor: pointer;
    background: #e6eaf4;
    margin-top: 23.7%;
    height: 3rem;

    .ticker {
      display: inline-block;
      height: 1rem;
      line-height: 3.5rem;
      white-space: nowrap;
      padding-right: 0%;
      box-sizing: content-box;
      padding-left: 100%;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-name: ticker;
      animation-name: ticker;
      -webkit-animation-duration: 30s;
      animation-duration: 30s;

      &__item {
        display: inline-block;
        padding: 0 1rem;
        font-size: 1.5rem;
        color: black;
      }
    }
  }

  @media (min-width: 1920px) {
    .tickermain {
      width: 85vw;

      .ticker-wrap {
        margin-left: 7%;
        display: flex;
        display: flex;
        height: 60px;
        overflow: hidden;
        position: relative;
        font-size: 30px;
        line-height: 1;
        font-weight: 600;
        letter-spacing: 2px;
        color: #000;
        cursor: pointer;
        background: #e6eaf4;
        margin-top: 20.6%;
      }
    }
  }

  @media (max-width: 1440px) {
    .ticker-wrap {
      margin-left: 7%;
      display: flex;
      width: 85vw;
      display: flex;
      height: 40px;
      overflow: hidden;
      position: relative;
      font-size: 23px;
      line-height: 1;
      font-weight: 600;
      letter-spacing: 2px;
      color: #000;
      cursor: pointer;
      background: #e6eaf4;
      margin-top: 24.6%;
      white-space: nowrap;
    }
  }

  @media (max-width: 876px) {
    .ticker-wrap {
      display: flex;
      width: 100vw;
      display: flex;
      height: 40px;
      margin-left: -1%;
      overflow: hidden;
      position: relative;
      font-size: 23px;
      line-height: 1;
      font-weight: 600;
      letter-spacing: 2px;
      color: #f44336;
      cursor: pointer;
      background: #e6eaf4;
      margin-top: 68vh;
    }
  }
`;

export default HeroBg;
