/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import Container from "../GlobalComponents/Container";
import Title from "../GlobalComponents/Title";
const Testimonials = () => {
  return (
    <section css={styles} className="testimonials">
      <Container className="container">
        <div className="testimonialHeading">
          <Title title="Donate with Bank / Wire Transer" />
        </div>
        <div className="aboutuscontent">
          <br />
          <ul>
            <li>National Westminister Bank </li>
            <li> Hayes End Branch</li>
            <li> Sort Code: 60-02-60</li>
            <li> Account No: 10004130</li>
          </ul>
          <br />
        </div>
      </Container>
      <Container className="container">
        <div className="testimonialHeading">
          <Title title="Donate with PayPal Payment" />
        </div>

        <br />
        <ul>
          <form
            action="https://www.paypal.com/donate"
            method="post"
            target="_top"
          >
            <input
              type="hidden"
              name="hosted_button_id"
              value="VGXPQE74TKZQA"
            />
            <input
              type="image"
              style={{ width: 100 }}
              src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
            <img
              alt=""
              border="0"
              src="https://www.paypal.com/en_GB/i/scr/pixel.gif"
              width="10"
              height="10"
            />
          </form>
          {/* <Button btnText="Donate here 💰"></Button> */}
        </ul>
      </Container>
    </section>
  );
};

const styles = css`
  display: flex;
  width: 100%;
  padding: 170px 190px;
  flex-direction: row;

  @media (min-width: 1920px) {
    width: 100%;
    padding: 270px 400px;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 140px 170px;
  }
  @media (max-width: 890px) {
  }
  .aboutuscontent {
    /* text-align: left;
    padding: 0 300px;
    font-size: 20px;
    color: #72789a;
    font-weight: 600; */

    h4 {
      font-weight: 600;
      color: #000;
      font-size: 22px;
    }
    h5 {
      font-weight: 600;
      color: #72789a;
    }

    ul {
      margin-left: 50px;
    }
    li {
      text-align: left;
      font-size: 17px;
      color: #72789a;
      font-weight: 500;
      line-height: 1.7;
    }
    p {
      padding: 30px 0 0 0;
      color: #72789a;
      font-weight: 700;
      line-height: 1.7;
      font-size: 17px;
    }
  }

  .testimonialHeading {
    text-align: left;
    margin-top: 100px;
    .title {
      font-size: 23px;
      text-align: left;
      font-weight: 700;
      padding: 30px 0 0 0;
    }
    p {
      padding: 30px 0 0 0;
      color: #72789a;
      font-weight: 700;
      line-height: 1.7;
      font-size: 17px;
    }
    h4 {
      padding: 30px 0 0 0;
      color: #72789a;
      font-weight: 700;
      line-height: 1.7;
      font-size: 27px;
    }
  }
  @media (max-width: 690px) {
    .aboutuscontent {
      p {
        font-size: 15px;
        padding: 0 8px;
        margin: 10px 0;
        font-weight: 600;
        br {
          display: none;
        }
      }
      h4 {
        font-size: 16px;
        padding: 0 8px;
        margin: 10px 0;
      }
      h5 {
        font-size: 18px;
        padding: 0 8px;
        margin: 10px 0;
      }
    }

    .testimonialHeading {
      margin-top: -30px;
      padding: -30px 0 0 0;
      p {
        font-size: 14px;
        padding: 0 0px;
        margin: 0px 0;
        text-align: justify;

        br {
          display: none;
        }
      }
      h4 {
        font-size: 28px;
        padding: 0 8px;
        margin: 10px 0;
        text-align: left;
      }
    }
  }

  @media (max-width: 1175px) {
    .container {
      max-width: 90%;
    }
  }
`;

export default Testimonials;
