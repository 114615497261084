/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const BlogCard = ({ blogImg, date, title, details }) => {
  return (
    <div css={styles} className="blogCard">
      <img src={blogImg} alt="blog" />

      <div className="blogCardInfo">
        <div className="blogCardDate">
          {/* <a href="#/">{date}</a> */}
          {/* <a href="#/">Admin</a> */}
          {/* <a href="#/">3</a> */}
        </div>
        <div className="blogCardDesc">
          <span className="title"># {title}</span> <br />
          <span className="date"># {date}</span>
          <a href="#/" dangerouslySetInnerHTML={{ __html: details }}></a>
        </div>
      </div>
    </div>
  );
};

const styles = css`
  width: 100%;
  max-width: 360px;
  border-radius: 10px;
  box-shadow: 0px 12px 32px #383b470f;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  flex-direction: column;
  .date {
    color: red;
    background: #bcdffb;
    background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
    text-decoration: none;
    font-size: 12px;
    padding: 4px;
    padding-right: 10px;
    padding-left: 10px;
    font-weight: 700;
    transition: color 300ms ease-in-out;
    line-height: 1.8;
  }
  &:hover {
    /* opacity: 0.8; */
    /* transform: scale(1.02);
    transition: all 0.1s ease-in-out; */
  }
  a {
    width: 100%;
  }

  img {
    width: 100%;
    height: 130px;
    display: block;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .blogCardInfo {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    margin-left: -140px;

    .blogCardDate {
      width: 100%;
      max-width: 37%;
      display: flex;
      flex-direction: column;
      text-align: right;
      margin-right: 3px;
      a {
        background: #2c2c2c;
        text-decoration: none;
        color: #fff;
        border-radius: 500px;
        /* border-top-right-radius: 10px;
        border-bottom-right-radius: 10px; */
        font-size: 13px;
        padding: 2px 0 0 0;
        padding: 1px;
        font-weight: 700;
        transition: color 300ms ease-in-out;
        line-height: 1.8;
        &:hover {
          /* color: #72789a; */
        }
      }
    }
    .blogCardDesc {
      width: 100%;
      max-width: 76%;
      flex-direction: column;
      span {
        color: #fff;
        background: #bcdffb;
        background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
        text-decoration: none;
        border-radius: 50px;
        align-items: flex-start;
        font-size: 11px;
        padding: 4px;
        padding-right: 10px;
        padding-left: 10px;
        font-weight: 500;
        transition: color 300ms ease-in-out;
        /* line-height: 1.8; */
        letter-spacing: 0.01em;
        margin: 1px;
      }
      a {
        color: #72789a;
        font-size: 12px;
        font-weight: 700;
        text-decoration: none;
        transition: color 300ms ease-in-out;
        line-height: 1.7;
        display: inline-block;
        width: 250px;
        overflow: hidden;
        white-space: wrap;
        text-overflow: ellipsis;
        height: 3.3em;
        margin-top: 10px;
      }
    }
  }
  @media (max-width: 840px) {
    max-width: 490px;
    margin-bottom: 10px;
  }
  @media (min-width: 841px) and (max-width: 1175px) {
    max-width: 47.5%;
  }
`;

export default BlogCard;
