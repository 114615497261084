/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Container from "../GlobalComponents/Container";
import Title from "../GlobalComponents/Title";

const Testimonials = () => {
  return (
    <section css={styles} className="testimonials">
      <Container>
        <div className="aboutusHeading">
          <Title title="What is Concern Sri Lanka Foundation (CSLF)?" />
          <p>
            We (CSLF) is a network of individuals promoting welfare of the
            people affected by Tsunami and the War.
            <br /> As well as supporting the development of Villages, Towns and
            Country.
          </p>
        </div>
        <div className="aboutuscontent">
          <h4>What is Our Mission?</h4>
          <h5>We aims are ,</h5>
          <ul>
            <li>to improve the education</li>
            <li> to improve the health</li>
            <li> to support one parent families</li>
            <li> to support orphants</li>
          </ul>
          <p>
            To improve the welfare of the people of the in Sri Lanka by:
            Assessing their needs. Highlighting the state of health. Harnessing
            the expertise of the Expatriate Tamil Community. Raise funds &
            channel resources to meet the identified needs.
          </p>
          <h4>What are our Objectives?</h4>
          <ul>
            <li>to support the development of the infrastructure.</li>
            <li>to develop & fund job opportunity projects</li>
            <li>to facilitate the Health Care seminars at schools.</li>
          </ul>
          <h4>Want to be a Donor of CSLF?</h4>
          <p>
            CSLF’s donorship will be open to all who subscribe to the objectives
            of the Organization.
          </p>
        </div>
      </Container>
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 110px 0;
  .aboutuscontent {
    h4 {
      font-weight: 800;
      color: #000;
      font-size: 18px;
    }
    h5 {
      font-weight: 600;
      color: #72789a;
    }

    ul {
      margin-left: 50px;
    }
    li {
      text-align: left;
      font-size: 17px;
      color: #72789a;
      font-weight: 500;
      line-height: 1.7;
    }
    p {
      padding: 30px 0 0 0;
      color: #72789a;
      font-weight: 500;
      line-height: 1.7;
      font-size: 17px;
      letter-spacing: 0.05em;
    }
  }

  .aboutusHeading {
    text-align: left;
    margin-top: 100px;

    .title {
      font-size: 22px;
      text-align: left;
      padding: 30px 0 0 0;
    }
    p {
      padding: 30px 0 0 0;
      color: #72789a;
      font-weight: 700;
      line-height: 1.7;
      font-size: 17px;
    }
    h4 {
      padding: 30px 0 0 0;
      color: #72789a;
      font-weight: 700;
      line-height: 1.7;
      font-size: 27px;
    }
  }
  /* xtra large screen */
  @media (min-width: 1920px) {
    width: 100%;
    padding: 140px 0;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 110px 0;
  }

  /* small Size */
  @media (max-width: 768px) {
    .aboutusHeading.title {
      margin-top: -80px;
    }
    .aboutuscontent {
      p {
        font-size: 15px;
        padding: 0 8px;
        margin: 10px 0;
        font-weight: 600;
        br {
          display: none;
        }
      }
      h4 {
        font-size: 16px;
        padding: 0 8px;
        margin: 10px 0;
      }
      h5 {
        font-size: 18px;
        padding: 0 8px;
        margin: 10px 0;
      }
    }

    .testimonialHeading {
      margin-top: -30px;
      padding: -30px 0 0 0;
      p {
        font-size: 14px;
        padding: 0 0px;
        margin: 0px 0;
        text-align: justify;

        br {
          display: none;
        }
      }
      h4 {
        font-size: 28px;
        padding: 0 8px;
        margin: 10px 0;
        text-align: left;
      }
    }
  }

  @media (max-width: 1175px) {
    .container {
      max-width: 90%;
    }
  }
`;

export default Testimonials;
