/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import Container from "../../GlobalComponents/Container";
import Logo from "./Logo";
import Menu from "./Menu";

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  function refreshPage() {
    window.location.reload(false);
  }
  useEffect(() => {});
  return (
    <nav css={styles}>
      <Container>
        <Logo />
        <Menu showMenu={showMenu} onClick={() => setShowMenu(!showMenu)} />
        <i
          id="burgerMenu"
          className={showMenu ? "fas fa-times" : "fas fa-bars"}
          onClick={() => setShowMenu(!showMenu)}
        ></i>
      </Container>
    </nav>
  );
};

const styles = css`
  width: 86%;
  margin-left: 7%;
  /* margin-right: 10%; */
  position: fixed;
  top: 0;
  padding: -1px;
  z-index: 5;
  color: #3f52e3;

  /* box-shadow: rgba(0, 0, 0, 0.06) 0px 10px 12px 0px; */
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  /* background: linear-gradient(#8a8a8a, #cbcbcc); */
  background: linear-gradient(90deg, #f5f7fa -40%, #c3cfe2 100%);
  background: linear-gradient(90deg, #606c88 0%, #3f4c6b 100%);
  background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);

  @media (max-width: 1210px) {
    /* background: linear-gradient(90deg, #667eea 0%, #764ba2 100%); */
    background: linear-gradient(90deg, #f5f7fa 0%, #c3cfe2 100%);
    background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
  }
  #burgerMenu {
    padding: 0px 0;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    display: none;
    float: right;
  }
  @media (max-width: 1210px) {
    width: 100vw;
    margin-left: 0%;
    padding: 10px;
    #burgerMenu {
      display: inline-block;
      color: #fff;
      font-size: 25px;
    }
  }
`;

export default Nav;
