import React, { useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Title from "../GlobalComponents/Title";
import Container from "../GlobalComponents/EventsContainer";
import BlogCard from "./EventsCard";
import { AnimateOnChange } from "react-animation";
import "react-animation/dist/keyframes.css";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import X from "./x";
import axios from "axios";
import BASE_URL from "../../constant";
const Blog = ({ eventsdiary }) => {
  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api_refresh();
    // setData(Data);
  });

  const api_refresh = () => {
    axios.get(`${BASE_URL}/ourevents`).then((response) => {
      console.log(response.data.ourevents);
      setAllData(response.data.ourevents);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    });
  };
  return (
    <section css={styles} className="blog" id="blog">
      <X />
      <Title title="Our Events" />

      <Container>
        {alldata.map((item) =>
          loading ? (
            <div style={{ padding: 150 }}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Link
              style={{ textDecoration: "none" }}
              to={`/eventsview/${item._id}`}
            >
              <AnimateOnChange className="foo" durationOut={500}>
                <BlogCard
                  blogImg={item.cover_image}
                  date={item.date}
                  title={item.title}
                />
              </AnimateOnChange>
            </Link>
          )
        )}
      </Container>
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 110px 0;
  height:100vh;
  /* xtra large screen */
  @media (min-width: 1920px) {
    width: 100%;
    padding: 140px 0;
  }

  /* large screen */
  @media (max-width: 1366px) {
    width: 100%;
    padding: 110px 0;
  }
  .cardskleton {
    width: 100%;
    width: 360px;
    border-radius: 15px;
    box-shadow: 10px 42px 32px #383b470f;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    flex-direction: column;
    margin: 10px;
    padding: 20px;
    padding-bottom: 100px;
  }
  .title {
    margin-top: 120px;
    font-size: 35px;
  }
  .container {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    padding: 60px 0 0 0;
    align-items: center;
    justify-content: center;
  }

  .blogCard {
    width: 100%;
    width: 340px;
    /* border-radius: 15px; */
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:hover {
      transform: scale(1.02);
      transition: all 0.4s ease-in-out;
      /* box-shadow: 0 5px 2px -2px black; */
    }
    margin: 10px;
  }
  .blogInfo {
    text-align: center;

    .title {
      margin-top: 100px;
    }
    p {
      color: #000;
      font-weight: 900px;
      line-height: 1.7;
      margin: 20px 0 0 0;
    }
  }
  .btn {
    margin: 0 auto;
  }
  .container {
    padding: 10px 0 0 0;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 641px) {
    .title {
      margin-top: -10%;
    }
    .blogCard {
      width: 99%;
    }
    .blogInfo {
      p {
        font-size: 15px;
        padding: 0 14px;
        br {
          display: none;
        }
      }
    }
  }
  @media (max-width: 840px) {
    .container {
      flex-direction: column;
      align-items: center;
      margin-top: -10px;
    }
  }
  @media (min-width: 841px) and (max-width: 1175px) {
    .container {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

export default Blog;
